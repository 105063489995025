export default {
  path: "payrolls",
  component: () => import("@/app/layouts/AppLayout.vue"),

  children: [
    {
      path: "",
      name: "payrolls",
      component: () => import("@/app/modules/payrolls/pages/Payrolls.vue"),

      meta: {
        title: "EREPORTER.NAVIGATION.PAYROLLS"
      }
    },

    {
      path: ":id([0-9]*)",
      name: "payrolls.show",
      component: () => import("@/app/modules/payrolls/pages/Payroll.vue"),
      props: route => ({
        payrollId: Number(route.params.id)
      }),

      meta: {
        title: "EREPORTER.PAYROLLS.PAYROLL"
      }
    }
  ]
};
