import {
  Action,
  Module,
  Mutation,
  VuexModule,
  config
} from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class ExpensesStore extends VuexModule {
  private _data: null | object = null;

  private _period = 0;

  get data(): null | object {
    return this._data;
  }

  get period(): number {
    return this._period;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Mutation
  setPeriod(data: number): void {
    this._period = data;
  }

  @Action
  fetchAll() {
    return Api.get("/api/ereporter/apps/my-expense-reports");
  }

  @Action
  fetchById(id: number) {
    return Api.get(`/api/ereporter/expense-report/${id}`);
  }

  @Action
  create(data: object) {
    return Api.post("/api/ereporter/expense-report", data);
  }

  @Action
  update(data: { id: number; body: object }) {
    return Api.patch(`/api/ereporter/expense-report/${data.id}`, data.body);
  }

  @Action
  remove(id: number) {
    return Api.delete(`/api/ereporter/expense-report/${id}`);
  }

  @Action
  uploadFiles(data: object) {
    return Api.post("/api/uploads/expense-reports", data);
  }

  @Action
  removeFile(id: number) {
    return Api.delete(`/api/uploads/${id}`);
  }
}
