
import { Component, Vue } from "vue-property-decorator";

import Connection from "@/app/modules/_global/classes/Connection";

const NetworkConnection = new Connection();

@Component
export default class AuthLayout extends Vue {
  devModeClicks = 0;
  devModeTimeout?: NodeJS.Timeout;

  get envAppMode() {
    return process.env.VUE_APP_MODE as string;
  }

  get appMode() {
    return this.$store.getters["app/mode"];
  }

  set appMode(mode: string) {
    if (mode == "Prod") this.resetDevMode();
    this.$store.commit("app/setMode", mode);
  }

  beforeDestroy() {
    NetworkConnection.removeListener();
  }

  enterDevMode() {
    if (this.devModeClicks < 10) {
      this.devModeClicks++;

      if (this.devModeTimeout != undefined) {
        clearTimeout(this.devModeTimeout);
      }

      this.devModeTimeout = setTimeout(() => {
        if (this.devModeClicks == 10) {
          this.appMode = "Dev";
        }

        this.resetDevMode();
      }, 1000);
    }
  }

  resetDevMode() {
    this.devModeClicks = 0;
    this.devModeTimeout = undefined;
  }
}
