import { Network, ConnectionStatus } from "@capacitor/network";
import { PluginListenerHandle } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";

export default class Connection {
  private handler: PluginListenerHandle;

  constructor() {
    this.handler = Network.addListener("networkStatusChange", status =>
      this.handleStatus(status)
    );
    this.networkStatus().then(status => this.handleStatus(status));
  }

  async networkStatus(): Promise<ConnectionStatus> {
    return await Network.getStatus();
  }

  private handleStatus(status: ConnectionStatus) {
    if (!status.connected) this.showAlert();
  }

  private async showAlert() {
    await Dialog.alert({
      title: "No Internet Connection",
      message: "Please check your Internet connection"
    });
  }

  removeListener(): void {
    this.handler.remove();
  }
}
