export const error = {
  path: "/error",
  component: () => import("@/app/layouts/ChildLayout.vue"),

  children: [
    {
      path: "api",
      name: "error",
      component: () => import("@/app/modules/_global/pages/Error.vue"),
      props: route => ({
        error: route.params.error
      })
    }
  ]
};
