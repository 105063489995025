import ApiHandler from "./ApiHandler";

import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";

import router from "@/app/router";
import store from "@/app/store";

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

interface ApiRequestInterface {
  setAuthorizationHeader(): void;
  setInterceptorsRequest(): void;
  setInterceptorsResponse(): void;
}

type Headers = {
  Accept: string;
  Authorization?: string;
  "Content-Type": string;
};

export default class Api extends ApiHandler implements ApiRequestInterface {
  private static _self: Api = new Api();
  private _axiosInstance: AxiosInstance;
  headers: Record<string, string> = DEFAULT_HEADERS;

  constructor() {
    super();

    this._axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_CORE_URL
    });

    this.setInterceptorsRequest();
    this.setInterceptorsResponse();
  }

  setAuthorizationHeader(): void {
    const apiToken = localStorage.getItem("api_token");

    if (apiToken) {
      this.headers = {
        Authorization: `Bearer ${apiToken}`,
        ...this.headers
      };
    } else delete this.headers.Authorization;
  }

  setInterceptorsRequest(): void {
    this._axiosInstance.interceptors.request.use(
      (config): AxiosRequestConfig => {
        if (store.getters["app/mode"] == "Dev") {
          config.baseURL = process.env.VUE_APP_CORE_DEV_URL;
        }

        if (store.getters["app/mode"] == "Test") {
          config.baseURL = process.env.VUE_APP_CORE_TEST_URL;
        }

        if (config.url?.match(/auth|password/g) != null) {
          config.params = {
            locale: store.getters["app/language"]
          };
        }

        store.commit("api/setApiFetching", true);
        return config;
      },
      error => {
        store.commit("api/setApiFetching", false);
        return error;
      }
    );
  }

  setInterceptorsResponse(): void {
    this._axiosInstance.interceptors.response.use(
      (response): Promise<AxiosResponse> => {
        store.commit("api/setApiFetching", false);

        if (response.data && response.data.impose_content) {
          const allowedTypes = ["html", "iframe", "form"];
          const content = response.data.impose_content;

          if (allowedTypes.includes(content.type)) {
            store.commit("content/setImposeContent", content);
            router.push({ name: `impose.${content.type}` }).catch(e => e);
          }
        }

        return ApiHandler.checkResponse(response);
      },
      error => {
        store.commit("api/setApiFetching", false);
        return ApiHandler.handleError(error);
      }
    );
  }

  static get(uri: string): Promise<AxiosResponse> {
    Api._self.setAuthorizationHeader();
    return Api._self._axiosInstance.get(uri, { headers: Api._self.headers });
  }

  static post(uri: string, data: object): Promise<AxiosResponse> {
    Api._self.setAuthorizationHeader();
    return Api._self._axiosInstance.post(uri, data, {
      headers: Api._self.headers
    });
  }

  static patch(uri: string, data?: object): Promise<AxiosResponse> {
    Api._self.setAuthorizationHeader();
    return Api._self._axiosInstance.patch(uri, data, {
      headers: Api._self.headers
    });
  }

  static delete(uri: string): Promise<AxiosResponse> {
    Api._self.setAuthorizationHeader();
    return Api._self._axiosInstance.delete(uri, {
      headers: Api._self.headers
    });
  }
}
