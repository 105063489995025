
import { Component, Vue } from "vue-property-decorator";
import { SplashScreen } from "@capacitor/splash-screen";

@Component
export default class AppLayout extends Vue {
  created() {
    SplashScreen.hide();
  }
}
