import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";
import { AppInfo } from "@capacitor/app";

@Module({ namespaced: true })
export default class AppStore extends VuexModule {
  private _data: null | object = null;
  private _appInfo: null | AppInfo = null;
  private _errorMessage: null | string = null;

  private _language = localStorage.getItem("app_language");
  private _mode = process.env.VUE_APP_MODE as string;

  get data(): null | object {
    return this._data;
  }

  get appInfo(): null | object {
    return this._appInfo;
  }

  get errorMessage(): null | string {
    return this._errorMessage;
  }

  get language(): null | string {
    return this._language;
  }

  get mode(): string {
    return this._mode;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Mutation
  setAppInfo(data: AppInfo): void {
    this._appInfo = data;
  }

  @Mutation
  setErrorMessage(data: string): void {
    this._errorMessage = data;
  }

  @Mutation
  setLanguage(data: string): void {
    localStorage.setItem("app_language", data);
    this._language = data;
  }

  @Mutation
  setMode(data: string): void {
    this._mode = data;
  }

  @Action
  changeLanguage(data: object) {
    return Api.get(`/api/translations?language=${data}`);
  }
}
