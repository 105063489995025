export const schedule = {
  path: "schedule",
  name: "schedule",
  component: () => import("@/app/modules/schedule/pages/Schedule.vue"),

  meta: {
    title: "EREPORTER.NAVIGATION.SCHEDULE"
  }
};

export const block = {
  path: "block",
  component: () => import("@/app/layouts/AppLayout.vue"),

  children: [
    {
      path: "",
      name: "block.create",
      component: () => import("@/app/modules/schedule/block/pages/Create.vue"),
      props: route => ({
        blockDate: route.params.date
      }),

      meta: {
        title: "EREPORTER.BLOCK.BLOCK_TIME"
      }
    },

    {
      path: ":id([0-9]*)",
      name: "block.edit",
      component: () => import("@/app/modules/schedule/block/pages/Edit.vue"),
      props: route => ({
        blockId: Number(route.params.id)
      }),

      meta: {
        title: "EREPORTER.BLOCK.EDIT_BLOCK_TIME"
      }
    }
  ]
};
