export default {
  path: "vacation",
  component: () => import("@/app/layouts/AppLayout.vue"),

  children: [
    {
      path: "",
      name: "vacation.requests.index",
      component: () => import("@/app/modules/vacation/pages/requests/Index.vue"),

      meta: {
        title: "EREPORTER.VACATION.REQUESTS"
      }
    },

    {
      path: "requests",
      name: "vacation.requests.create",
      component: () => import("@/app/modules/vacation/pages/requests/Create.vue"),

      meta: {
        title: "EREPORTER.VACATION.REQUEST"
      }
    },

    {
      path: "requests/:id([0-9]*)",
      name: "vacation.requests.edit",
      component: () => import("@/app/modules/vacation/pages/requests/Edit.vue"),
      props: route => ({
        id: Number(route.params.id)
      }),

      meta: {
        title: "EREPORTER.VACATION.REQUEST"
      }
    }
  ]
};