
import { Component, Vue } from "vue-property-decorator";
import { PluginListenerHandle } from "@capacitor/core";
import { App } from "@capacitor/app";

import Connection from "@/app/modules/_global/classes/Connection";
import Push from "@/app/modules/_global/classes/Push";

import moment from "moment";

const NetworkConnection = new Connection();

@Component({
  components: {
    NavDrawer: () => import("@/app/layouts/components/NavigationDrawer.vue"),
    Snackbar: () => import("@/app/modules/_global/components/Snackbar.vue")
  }
})
export default class MainLayout extends Vue {
  private appStateChangeListener: PluginListenerHandle;

  constructor() {
    super();

    this.appStateChangeListener = App.addListener("appStateChange", state => {
      if (state.isActive) {
        this.fetchTasks();

        if (this.$route.name == "task") {
          this.$router.replace({
            name: "task",
            params: this.$route.params,
            query: { updated_at: moment().toISOString() }
          });
        }
      }
    });
  }

  appBar = true;
  navDrawer = false;

  beforeDestroy() {
    App.removeAllListeners();
    NetworkConnection.removeListener();

    this.appStateChangeListener?.remove();

    if (this.deviceInfo.platform != "web") {
      Push.removeAllListeners();
    }
  }

  created() {
    if (this.deviceInfo.platform != "web") {
      Push.registerNotifications().then(() => {
        Push.addListeners(this.deviceInfo);
      });
    }
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get unreadNews() {
    return this.userData.notifications.filter(
      news => news.reading_date == null
    );
  }

  get notrufTile() {
    return this.appData.tiles.find(tile => tile.type.name == "Notruf");
  }

  get scheduleType() {
    return this.$store.getters["schedule/type"];
  }

  set scheduleType(value) {
    this.$store.commit("schedule/setType", value);
  }

  hasRole(role: string): boolean {
    return this.userData.roles.some(r => r.slug == role);
  }

  handleRouterBack() {
    if (this.$route.name == "schedule" && this.scheduleType != "month") {
      this.scheduleType = "month";
      return;
    }

    return this.$router.back();
  }

  fetchTasks() {
    this.$store.dispatch("tasks/fetchAll").then(({ data }) => {
      this.$store.commit("tasks/setData", data.data);
    });
  }
}
