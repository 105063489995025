import Vue from "vue";
import Vuex from "vuex";

// Modules
import AuthStore from "@/app/modules/auth/store";
import TasksStore from "@/app/modules/tasks/store";
import ExpensesStore from "@/app/modules/expenses/store";
import ScheduleStore from "@/app/modules/schedule/store";
import VacationStore from "@/app/modules/vacation/store";
import PayrollsStore from "@/app/modules/payrolls/store";
import * as GlobalStore from "@/app/modules/_global/store";
import * as ProfileStore from "@/app/modules/profile/store";

Vue.use(Vuex);

export default new Vuex.Store({
  // `strict` means that we can't modify state directly (only through the mutations)
  strict: process.env.NODE_ENV != "production",

  modules: {
    auth: AuthStore,
    tasks: TasksStore,
    expenses: ExpensesStore,
    schedule: ScheduleStore,
    vacation: VacationStore,
    payrolls: PayrollsStore,

    api: GlobalStore.ApiFetchingStore,
    device: GlobalStore.DeviceStore,
    snack: GlobalStore.SnackStore,
    app: GlobalStore.AppStore,
    profile: ProfileStore.ProfileStore
  }
});
