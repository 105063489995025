import { LocaleMessageObject } from "vue-i18n";
import moment from "moment";

import i18n from "@/i18n";
import store from "@/app/store";
import vuetify from "@/plugins/vuetify";

// Classes
import Device from "@/app/modules/_global/classes/Device";
import { App } from "@capacitor/app";

export default class AppClass {
  static device = new Device();

  static async init(): Promise<void> {
    const appLanguage = await this.setAppLanguage();
    const deviceInfo = await this.device.getDeviceInfo();

    if (deviceInfo.platform != "web") {
      store.commit("app/setAppInfo", await App.getInfo());
    }

    return new Promise((resolve, reject) => {
      store
        .dispatch("app/changeLanguage", appLanguage)
        .then(({ data }) => {
          this.setLocale(appLanguage, data);

          store
            .dispatch("profile/fetch")
            .then(({ data }) => {
              store.commit("profile/setData", data);
              resolve();
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static setLocale(locale: string, data: LocaleMessageObject): void {
    i18n.mergeLocaleMessage(locale, data);
    i18n.locale = locale;

    vuetify.framework.lang.current = locale;
    moment.locale(locale);

    store.commit("app/setLanguage", locale);
  }

  private static async setAppLanguage(): Promise<string> {
    const deviceLanguage = await this.device.getLanguageCode();
    const appLanguage = store.getters["app/language"];

    const allowedLanguages = ["de", "en", "fr"];
    const defaultLanguage = "de";

    if (appLanguage != null && allowedLanguages.includes(appLanguage)) {
      return appLanguage;
    } else if (allowedLanguages.includes(deviceLanguage.value)) {
      return deviceLanguage.value;
    } else {
      return defaultLanguage;
    }
  }
}
