import {
  Action,
  Module,
  Mutation,
  VuexModule,
  config
} from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class PersonalInterestsStore extends VuexModule {
  private _data: null | object = null;

  get data(): null | object {
    return this._data;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Action
  fetch() {
    return Api.get("/api/apps/employee/profile");
  }

  @Action
  updateAppDevice(data: object) {
    return Api.post("/api/profile/app-device", data);
  }

  @Action
  updateAppLanguageId(data: object) {
    return Api.patch("/api/app-language-id", data);
  }
}
