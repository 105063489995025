import {
  Action,
  Module,
  Mutation,
  VuexModule,
  config
} from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class PayrollsStore extends VuexModule {
  private _data: null | object = null;

  private _period = 0;

  get data(): null | object {
    return this._data;
  }

  get period(): number {
    return this._period;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Mutation
  setPeriod(data: number): void {
    this._period = data;
  }

  @Action
  fetchAll() {
    return Api.get("/api/myereporter/payrolls");
  }

  @Action
  fetchById(id: number) {
    return Api.get(`/api/myereporter/payrolls/${id}`);
  }
}
