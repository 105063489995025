import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// Store
import store from "@/app/store";

// Classes
import App from "@/app/modules/_global/classes/App";

// Route modules
import tasks from "@/app/modules/tasks/router";
import expenses from "@/app/modules/expenses/router";
import { schedule, block } from "@/app/modules/schedule/router";
import vacation from "@/app/modules/vacation/router";
import payrolls from "@/app/modules/payrolls/router";
import profile from "@/app/modules/profile/router";
import settings from "@/app/modules/settings/router";

import {
  login,
  logout,
  forgotPassword,
  register
} from "@/app/modules/auth/router";

import { error } from "@/app/modules/_global/router";

// Layouts
import MainLayout from "@/app/layouts/MainLayout.vue";
import AuthLayout from "@/app/layouts/AuthLayout.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "tasks",
    component: MainLayout,

    beforeEnter: (to, from, next) => {
      App.init().finally(() => next());
    },

    meta: {
      requiresAuth: true
    },

    children: [tasks, expenses, schedule, block, vacation, payrolls, profile, settings]
  },

  {
    path: "/auth",
    component: AuthLayout,

    children: [login, forgotPassword, register]
  },

  logout,
  error
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes

  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  // scrollBehavior: (to, from, savedPosition) => ({ x: 0, y: 0 })
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["auth/hasApiToken"]) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
