export default {
  path: "tasks",
  component: () => import("@/app/layouts/AppLayout.vue"),

  children: [
    {
      path: "",
      name: "tasks",
      component: () => import("@/app/modules/tasks/pages/Tasks.vue"),

      meta: {
        title: "EREPORTER.NAVIGATION.TASKS"
      }
    },

    {
      path: "create",
      name: "tasks.create",
      component: () => import("@/app/modules/tasks/pages/CreateTask.vue"),

      meta: {
        title: "EREPORTER.TASK.REPORT"
      }
    },

    {
      path: ":id([0-9]*)",
      name: "task",
      component: () => import("@/app/modules/tasks/pages/Task.vue"),
      props: route => ({
        taskId: Number(route.params.id)
      }),

      meta: {
        title: "EREPORTER.TASK.DETAILS"
      }
    }
  ]
};
