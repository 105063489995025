import {
  Action,
  Module,
  Mutation,
  VuexModule,
  config
} from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class ScheduleStore extends VuexModule {
  private _focus = "";
  private _type = "month";

  get focus(): string {
    return this._focus;
  }

  get type(): string {
    return this._type;
  }

  @Mutation
  setFocus(data: string): void {
    this._focus = data;
  }

  @Mutation
  setType(data: string): void {
    this._type = data;
  }

  @Action
  fetch(dateRange: Record<string, string>) {
    const query = `date_from=${dateRange.start}&date_to=${dateRange.end}`;
    return Api.get(`/api/planning/apps/schedule?${query}`);
  }

  @Action
  block(data: object) {
    return Api.post("/api/myereporter/tasks-blocks", data);
  }

  @Action
  fetchBlock(id: number) {
    return Api.get(`/api/planning/apps/block/${id}`);
  }

  @Action
  updateBlock(data: { id: number }) {
    return Api.patch(`/api/planning/apps/block/${data.id}`, data);
  }

  @Action
  removeBlock(id: number) {
    return Api.delete(`/api/planning/apps/block/${id}`);
  }

  @Action
  getReasonsList() {
    return Api.get("/api/apps/ereporter/planning/block/reasons");
  }

  @Action
  getReiterationsList() {
    return Api.get("/api/planning/event/reiterations");
  }
}
