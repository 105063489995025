export default {
  path: "expenses",
  component: () => import("@/app/layouts/AppLayout.vue"),

  children: [
    {
      path: "",
      name: "expenses",
      component: () => import("@/app/modules/expenses/pages/Expenses.vue"),

      meta: {
        title: "EREPORTER.NAVIGATION.EXPENSES"
      }
    },

    {
      path: "create",
      name: "expenses.create",
      component: () => import("@/app/modules/expenses/pages/CreateExpense.vue"),

      meta: {
        title: "EREPORTER.NAVIGATION.EXPENSES"
      }
    },

    {
      path: ":id([0-9]*)",
      name: "expenses.update",
      component: () => import("@/app/modules/expenses/pages/UpdateExpense.vue"),
      props: route => ({
        expenseId: Number(route.params.id)
      }),

      meta: {
        title: "EREPORTER.EXPENSE.DETAILS"
      }
    }
  ]
};
